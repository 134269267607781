import React, { useState } from "react"
import './css/jobs.css'
import { Link, useNavigate } from 'react-router-dom';

const Job = ({job, navigate})=>{
    const {title, description, _id, createdAt, price}= job;
    

    return(
        <Link to={`/id/${_id}`} key={_id} className="card" onClick={(e) => {
            e.preventDefault();
            navigate(`/id/${_id}`);
        }}>
            <h1 className="heading card-title">
                {title}
            </h1>
            <p className="card-desc">
                {description}
            </p>
            <h4 className="card-price"> 
                <i className="fa-solid fa-tags"></i> 
                <span>KSH: {price}</span>
            </h4>
            <p className="card-date">
                <i className="fa-solid fa-calendar-days"></i>  
                <span> {new Date(createdAt).toDateString()}</span>    
            </p>
        </Link>
    )
}



const Jobs = ({data}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const navigate = useNavigate();

    // Calculate the index of the first and last records to display
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  
    // Slice the data to only display the current page's records
    const currentRecords = data ? data.slice(indexOfFirstRecord, indexOfLastRecord) : [];
  
    // Calculate the total number of pages based on the number of records and records per page
    const totalPages = data ? Math.ceil(data.length / recordsPerPage) : 0;
  
    // Handle navigation to the next or previous page
    const handleNextPage = () => {
      setCurrentPage(currentPage + 1);
    };
  
    const handlePreviousPage = () => {
      setCurrentPage(currentPage - 1);
    };
  
    return(
      <div id="container">
        {currentRecords.map(d=>d.doc).map(d => Job({job:d, navigate}))}
        {totalPages > 1 && (
          <div className="pagination">
            <button disabled={currentPage === 1} onClick={handlePreviousPage}>Previous</button>
            
            <span>Page {currentPage} of {totalPages}</span>

            <button disabled={currentPage === totalPages} onClick={handleNextPage}>Next</button>
          </div>
        )}
      </div>
    );
  };

export default Jobs