import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEdit} from "@fortawesome/free-solid-svg-icons";
import Loader from "./loader";

import './css/job.css'
import VerificationLoader from "./VerificationLoader";
import AccountLoader from "./AccountLoader";

const JobDetails = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    fetch(`https://api.workhubwriters.com/job/id/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setJob(data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleLike = () => {
    setLiked(true);
  };

  const handleApply = () => {
    // Code to apply for the job
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
    <AccountLoader/>
    <VerificationLoader/>
    <div className="job-details">
      <h1>{job.title}</h1>
      <div className="job-description">
        <p>{job.description}</p>
      </div>
      
      
      
      
      <div className="job-requirements">
        <h3>Requirements:</h3>
        <ul>
          {job.requirements.map((req, index) => (
            <li key={index}>{req}</li>
          ))}
        </ul>
      </div>
      <div className="job-info">
        <p>
          <FontAwesomeIcon icon={faClock} />
          <span>{job.timeExpected}</span>
        </p>
        <p>
          <FontAwesomeIcon icon={faEdit} /> 
          <span>{job.expertiseLevel}</span>
        </p>
      </div>

      <div className="job-keywords">
          {job.keywords.map((req, index) => (
            <li key={index}>{req}</li>
          ))}
      </div>
        
      <div className="job-price-time">
        <div className="job-price">
            <i className="fas fa-tags"></i> <span className="amount-val">KSH: {job.price}</span>
        </div>
        <div className="job-time">
            <i className="far fa-calendar-alt"></i>  <span className="date-val">{new Date(job.createdAt).toDateString()}</span>
        </div>
    </div>

      <div className="job-buttons">
        
        <div className="apply-btn">
            <button onClick={handleApply}>Apply</button>
        </div>
        <div className="like-btn-container">
            <button onClick={handleLike} className={'like-btn '+liked ? "liked" : "like"}>
                <i class="fa-solid fa-heart"></i>
            </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default JobDetails;
