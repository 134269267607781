import { useEffect } from 'react';

function AccountLoader() {
  useEffect(() => {
    const writer = JSON.parse(localStorage.getItem('writer'));

    if (!writer || !writer._id) {
      window.location.href = '/join'; // Redirect to external page
      return;
    }

    fetch('https://api.workhubwriters.com/account', {
      method: 'GET',
      headers: {
        key: writer.accessKey,
        user: writer._id,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((d) => {
        if (!d || !d._id) {
          localStorage.clear();
          window.location.href = '/join'; // Redirect to external page
        } else {
          writer.description = d.description;
          localStorage.setItem('writer', JSON.stringify(d));
          document.getElementById('account-loader').style.display = 'none';
        }
      })
      .catch((err) => {
        localStorage.clear();
        window.location.href = '/join'; // Redirect to external page
      });
  }, []);

  return (
    <div id="account-loader">
      <div className="account-loader"></div>
    </div>
  );
}

export default AccountLoader;
