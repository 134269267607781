import React, { useRef } from "react";
import images from './images/export.images.jsx'
const {
    article, book, edit, ghost, product, resume, script, webpage, translate
} = images;



const ScrollLeft = ({ containerRef }) => {
    const handleClick = () => {
      const container = containerRef.current;
      container.scrollTo({ left: 0, behavior: "smooth" });
    };
    return(
        <button disabled="" onClick={handleClick}>
            <i className="fa-solid fa-angle-left"></i>
        </button>
    )
}
const ScrollRight = ({ containerRef }) => {
    const handleClick = () => {
      const container = containerRef.current;
      const maxScroll = container.scrollWidth - container.clientWidth;
      container.scrollTo({ left: maxScroll, behavior: "smooth" });
    };
  
    return (
      <button onClick={handleClick}>
        <i className="fa-solid fa-angle-right"></i>
      </button>
    );
};
  const PopularTypesContainer = React.forwardRef((props, ref)=>{
    const {search} = props
    let counter = 0;
    return (
    <div className="popular-types-container" ref={ref}>

        {[
            {"text":"Articles & Blog Posts","img":article},{"text":"Translation","img":translate},
            {"text":"Ghost Writing","img":ghost},
            {"text":"Proofreading & Editing","img":edit},
            {"text":"Resume Writing","img":resume},
            {"text":"Website Content","img":webpage},
            {"text":"Product Description","img":product},
            {"text":"Script Writing","img":script},
            {"text":"Book Editing","img":book}].map(({text, img})=>(
            <article className="article-type" key={counter++} onClick={()=>{
                search(text);
            }}>
                <div className="article-type-container">
                    <img src={img} alt="" />
                    <span>{text}</span>
                    <i className="fa-solid fa-arrow-right"></i>
                </div>
            </article>
        ))}        
    </div>
    )
})

const PopularTypes = ({search}) => {
    const containerRef = useRef(null);
  
    return (
      <section id="popular-types">
        <h1>Most Popular in Writing &amp; Translation</h1>
        <div className="popular-types-control">
          <ScrollLeft containerRef={containerRef}/>
          <ScrollRight containerRef={containerRef} />
        </div>
        <PopularTypesContainer ref={containerRef} search={search}/>
      </section>
    );
  };
export default PopularTypes;