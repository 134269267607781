import article from './article.svg'
import book from './book.svg'
import edit from './edit.svg'
import ghost from './ghost.svg'
import loader from './loader.svg'
import product from './product.svg'
import resume from './resume.svg'
import script from './script.svg'
import translate from './translate.svg'
import webpage from './webpage.svg'
import writingIcon from './writing-icon.jpg'

const images = {
    article, book, edit, ghost, loader, product, resume, script, webpage, translate, writingIcon
}

export default images