import React, { useState } from "react";

import './css/nav.css'
import PopularTypes from "./popularTypes";
import logo from './images/logo.svg';

const Nav = ({search})=>{
    const [query, setQuery]=useState('');    
    return(
    <nav>
        <div className="job-header">
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div id="header-search-bar">
                <div className="search-icon-container">
                    <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                <div className="input-container">
                    <input type="text" name="search-input" id="search-input" placeholder="Search for a job" onChange={(e)=>{
                        setQuery(e.target.value)
                    }}/>
                </div>
                <div id="header-search-btn" onClick={()=>{
                    search(query)}}>
                    Search
                </div>
            </div>
        </div>
        <PopularTypes search={search}/>
    </nav>
    )
}

export default Nav;