import React from "react";

import loaderImage from './images/loader.svg'

const Loader = ()=>{
    return(
        <div className="loader">
            <img src={loaderImage} alt="" />
        </div>
    )
}

export default Loader