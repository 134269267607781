import { useState, useEffect } from 'react';
import Jobs from './jobs.jsx';
import Nav from './nav';
import Loader from './loader';


import AccountLoader from './AccountLoader';

function JobsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const query = params.get('query');
    if (query) {
      setSearchQuery(query);
    }
  }, []);

  useEffect(() => {
    if (!searchQuery) {
      return;
    }
    // Fetch data from API endpoint
    const usr = JSON.parse(localStorage.getItem('writer'));
    setLoading(true);
    fetch(`https://api.workhubwriters.com/search/job/${searchQuery}`, {
      method: 'GET',
      headers: {
        'key': usr.accessKey,
        'user': usr._id
      }
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [searchQuery]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <>
    <AccountLoader/>
    <div>
      <Nav search={handleSearch} />
      {loading ? <Loader /> : ''}
      <Jobs data={data} search={handleSearch} />
    </div>
    </>   
  );
}

export default JobsPage;
