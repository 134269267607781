import { useEffect } from 'react';

function VerificationLoader() {
  useEffect(() => {
    const writer = JSON.parse(localStorage.getItem('writer'));

    fetch('https://api.workhubwriters.com/account/verification', {
      method: 'GET',
      headers: {
        key: writer.accessKey,
        user: writer._id,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((d) => {
        if (!d || !d.verification) {
          window.location.href = '/pro-only'; // Redirect to external page
        } else {
          document.getElementById('verification-loader').style.display = 'none';
        }
      })
      .catch((err) => {
        window.location.href = '/pro-only'; // Redirect to external page
      });
  }, []);

  return (
    <div id="verification-loader">
      <div className="verification-loader"></div>
    </div>
  );
}

export default VerificationLoader;
